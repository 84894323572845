import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 0 0;
  color: #fff;
  p {
    ${props => props.theme.font_size.small};
    color: #fff;
    margin: 1rem 0;
  }
`
export const FeaturesSection = styled.section`
  background: #0baee7;
  position: relative;
`
export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2px 1fr 2px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-bottom: 35vh;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    padding: 5vh 0 10vh;
  }
`
export const Card = styled.div`
  margin: 1rem 2rem;
  text-align: center;
`

export const Title = styled.h5`
  ${props => props.theme.font_size.regular};
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: center;
  padding: 0 1rem;
`

export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`

export const VerticalDivider = styled.div`
  height: 80%;
  width: 100%;
  background-color: #fff;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
