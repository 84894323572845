import React from "react"

import Layout from "@common/Layout"
import Header from "@sections/software/Header"
import Info from "@sections/software/Info"
import Features from "@sections/software/Features"
import Deployment from "@sections/software/Deployment"
import MoreInfo from "@sections/software/MoreInfo"
import Brochure from "@common/Brochure"
import Contact from "@common/Contact"
import Footer from "@common/Footer"
import SlideShow from "@sections/software/SlideShow/SlideShow"

const NAV_ITEMS = ["Info", "Features", "Deployment", "Contact"]

const Software = () => (
  <Layout
    title="Software"
    color="rgba(10, 180, 233, 0.9)"
    transparent={false}
    navItems={NAV_ITEMS}
  >
    <Header />
    <Info />
    <Features />
    <Deployment />
    <MoreInfo />
    <SlideShow />
    <Brochure />
    <Contact />
    <Footer />
  </Layout>
)

export default Software
