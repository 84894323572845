import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { ReactComponent as BackgroundArt } from "@static/deployment_art.svg"
import { ReactComponent as BackgroundArt1 } from "@static/pulse_line_2.svg"
import { Title, Text, Content } from "./style"

const Deployment = () => {
  const data = useStaticQuery(graphql`
    query {
      art1: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_deployment_art_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      art2: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_deployment_art_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="deployment">
      <Title>Two-Phase Deployment System</Title>
      <Content>
        <BackgroundArt1 style={{ position: "absolute", top: "0", left: "0" }} />
        <Text>
          <span>The first phase</span> involves the use of HL7 standard
          recommended communication procedures to connect to the hospital
          servers without the need to bring any new device into the hospital.
        </Text>
        <Img fluid={data.art1.childImageSharp.fluid} className="image1" />
        <Img fluid={data.art2.childImageSharp.fluid} className="image2" />
        <Text>
          <span>In the second phase</span>, the smart hospital devices are
          introduced to the hospital to abolish the need for an onsite server in
          the facility. The system is designed for the faster unification and
          implementation of Biox SmartHospital software package within the
          hospital’s data centers and workflow to ensure faster treatment
          response and more room for patients in the hospital.
        </Text>
        <BackgroundArt
          className="backgroundArt"
          style={{ position: "absolute", bottom: "-3%", right: "1rem" }}
        />
      </Content>
    </section>
  )
}

export default Deployment
