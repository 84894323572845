import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import CtaButton from "@common/CtaButton"

import { Hero, StyledContainer, Divider } from "./style"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(
          sourceInstanceName: { eq: "background" }
          name: { eq: "software_hero_background" }
        ) {
          relativePath
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        className="hero"
        Tag="section"
        fluid={data.hero.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "top left",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Hero>
          <StyledContainer>
            <h2>Biox SmartHospital</h2>
            <h1>Health Care Software</h1>
            <AnchorLink href="#info">
              <CtaButton text="Learn more" />
            </AnchorLink>
          </StyledContainer>
        </Hero>
        <Divider viewBox="0 0 1920 300" aria-hidden="true">
          <path
            d="M 0 200 Q 480 300 960 200 Q 1440 100 1920 200 L 1920 300 L 0 300 L 0 200"
            fill="#0BAEE7"
          ></path>
          <path
            d="M 0 240 Q 480 300 960 200 Q 1440 100 1920 240 L 1920 300 L 0 300 L 0 200"
            fill="white"
          ></path>
        </Divider>
      </BackgroundImage>
    )}
  />
)

export default Header
