import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import CtaButton from "@common/CtaButton"

import {
  StyledContainer,
  StyledContainer2,
  StyledContainer3,
  SecondSection,
  ThirdSection,
  InfoSection,
  Text,
  Content2,
  Content3,
  Title2,
  Title3,
  Divider,
  Content3Wrapper,
  ExternalLink,
} from "./style"

const Info = () => {
  const data = useStaticQuery(graphql`
    query {
      background1: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "software_info_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      background2: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "software_info_background_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      softwareMockup1: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_info_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      softwareMockup2: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_info_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <InfoSection id="info">
      <StyledContainer>
        <Text>
          Biox SmartHospital software package offers a cloud-based remote
          patient monitoring solution that can allow physicians to get their
          patients’ data including vital parameters, reports, and medical
          history at any time from any device. Our solution is focused on
          offering efficient device tracking and organization solutions to the
          physicians to ensure ease of access to information about patients. It
          can also allow doctors to track the hospitalization and discharge
          summary of patients in a few simple steps. Biox also offer an
          integrated system of organization to allow for device and patient
          transfers.
        </Text>
      </StyledContainer>
      <SecondSection>
        <BackgroundImage
          className="background1"
          Tag="section"
          fluid={data.background1.childImageSharp.fluid}
          style={{
            width: "100%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <StyledContainer2>
            <Content2>
              <Title2>
                A cloud-based remote patient monitoring solution and hospital
                organization tool
              </Title2>
              <Img fluid={data.softwareMockup1.childImageSharp.fluid} />
            </Content2>
          </StyledContainer2>
        </BackgroundImage>
      </SecondSection>
      <BackgroundImage
        className="background2"
        Tag="section"
        fluid={data.background2.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <ThirdSection>
          <StyledContainer3>
            <Content3>
              <Img fluid={data.softwareMockup2.childImageSharp.fluid} />
              <Content3Wrapper>
                <Title3>One account for all devices</Title3>
                <ExternalLink
                  href="https://service.bioxcorp.net/app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CtaButton text={"Join us"} />
                </ExternalLink>
              </Content3Wrapper>
            </Content3>
          </StyledContainer3>
        </ThirdSection>
        <Divider viewBox="0 0 1920 400" aria-hidden="true">
          <path
            d="M 0 200 Q 480 400 960 200 Q 1440 0 1920 200 L 1920 400 L 0 400 L 0 200"
            fill="rgba(11,174,231,.5)"
          ></path>
          <path
            d="M 0 240 Q 480 400 960 200 Q 1440 0 1920 240 L 1920 400 L 0 400 L 0 200"
            fill="#0BAEE7"
          ></path>
        </Divider>
      </BackgroundImage>
    </InfoSection>
  )
}

export default Info
