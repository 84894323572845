import React from "react"

import { ReactComponent as AutomationIcon } from "@static/icons/software_automation.svg"
import { ReactComponent as NotificationIcon } from "@static/icons/software_notification.svg"
import { ReactComponent as RealTimeIcon } from "@static/icons/software_real_time.svg"

import {
  StyledContainer,
  FeaturesSection,
  Content,
  Card,
  Title,
  Divider,
  Text,
  VerticalDivider,
} from "./style"

const Features = () => (
  <FeaturesSection id="features">
    <StyledContainer>
      <Content>
        <Card>
          <AutomationIcon />
          <Title>Fully Automated Hospital</Title>
          <Text>
            Biox SmartHospital software package allows you to organize your
            hospital according to your needs, know where every patient and
            device is at all times and sort the patients you want to closely
            monitor.
          </Text>
        </Card>
        <VerticalDivider />
        <Card>
          <NotificationIcon />
          <Title>Integrated Notification System</Title>
          <Text>
            Advanced features integrated within the system to help clinicians
            receive notifications of critical vital signs. The notification
            system supported by Biox SmartHospital software package allows
            physicians to customize the parameters of abnormal vital signs to a
            specific value set.
          </Text>
        </Card>
        <VerticalDivider />
        <Card>
          <RealTimeIcon />
          <Title>Access patient's data in real-time from any device</Title>
          <Text>
            Biox SmartHospital software package is easy and quick to deploy and
            integrate into the hospitals current system without the need for
            additional hardware. You are one click away from real-time patient's
            data.
          </Text>
        </Card>
      </Content>
    </StyledContainer>
    <Divider viewBox="0 0 1920 400" aria-hidden="true">
      <path
        d="M 0 200 Q 480 400 960 200 Q 1440 0 1920 200 L 1920 400 L 0 400 L 0 200"
        fill="#84D6F3"
      ></path>
      <path
        d="M 0 240 Q 480 400 960 200 Q 1440 0 1920 240 L 1920 400 L 0 400 L 0 200"
        fill="#fff"
      ></path>
    </Divider>
  </FeaturesSection>
)

export default Features
