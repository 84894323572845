import styled from "styled-components"

import { Container } from "@components/global"

export const Hero = styled.div`
  height: 110vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 100vh;
    background-size: cover;

    &::after {
      background-size: cover;
    }
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  color: #000;
  margin-bottom: 3rem;

  h1 {
    margin: 2rem 0;
    font-weight: 700;
    width: 25%;
  }
  span {
    color: #0baee7;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    align-items: center;
    h1 {
      margin: 2rem 0;
      font-weight: 700;
      width: auto;
      text-align: center;
    }
  }
`

export const ArrowWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 16vh;
  width: 64px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 100;
`

export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`
