import styled from "styled-components"

import { Container } from "@components/global"

export const InfoSection = styled.section`
  position: relative;
`

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 64px 2rem 10vh;

  p {
    ${props => props.theme.font_size.small};
    text-align: justify;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 64px 1rem 10vh;
    p {
      text-align: left;
    }
  }
`

export const StyledContainer2 = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 0 2rem;

  p {
    ${props => props.theme.font_size.small};
    text-align: justify;
  }
`

export const StyledContainer3 = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 10vh 2rem;

  p {
    ${props => props.theme.font_size.small};
    text-align: justify;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 10vh 1rem 0;
  }
`

export const FirstSection = styled.div``

export const SecondSection = styled.div`
  max-height: 80vh;
`

export const ThirdSection = styled.div`
  padding-bottom: 20vh;
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;
  padding: 0 2rem;
`

export const Content2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    padding: 10vh 0;
    text-align: center;
  }
`

export const Content3 = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
  }
`

export const Title2 = styled.h3`
  align-self: center;
  justify-self: center;
  color: #fff;
  font-weight: 700;
`

export const Title3 = styled.h3`
  font-weight: 700;
  margin-bottom: 2rem;
`

export const Content3Wrapper = styled.div`
  align-self: center;
  justify-self: center;
  text-align: center;
`

export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`

export const ExternalLink = styled.a``
