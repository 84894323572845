import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  height: 80vh;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 30vh;
  }
`
