import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  MoreInfoSection,
  StyledContainer,
  Title,
  Text,
  Content,
  TextWrapper,
} from "./style"

const MoreInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      art: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_more_info_mockup" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <MoreInfoSection id="more-info">
      <StyledContainer>
        <Content>
          <TextWrapper>
            <Title>
              Access to live and historical data of both hospitalized and
              discharged patients
            </Title>
            <Text>
              Biox’s SmartHospital software package offers a cloud-based remote
              patient monitoring solution that allows clinicians to access
              patients’ physiological data, including all vital signs, at any
              time from any device. While traditional telemetry only monitors
              patients’ heart rate and rhythm – Biox’s Smart Hospital software
              continuously monitors all vitals signs in addition to heart rate
              and rhythm. The display of vital signs in real time ensures faster
              analysis and assessment of patients’ current clinical condition.
              Biox’s SmartHospital software package is designed to provide
              access to the medical history of currently and previously
              hospitalized patients to support quick diagnosis and evaluation of
              their current health conditions. The vital sign history is
              displayed in the form of graphs to help physicians track the
              health status and progress of patients with just a glance.
            </Text>
          </TextWrapper>
          <Img fluid={data.art.childImageSharp.fluid} />
        </Content>
      </StyledContainer>
    </MoreInfoSection>
  )
}

export default MoreInfo
