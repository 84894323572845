import styled from "styled-components"

import { Container } from "@components/global"

export const MoreInfoSection = styled.section`
  background-color: #09bfec;
`

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 10vh 0;
  color: #fff;
  p {
    ${props => props.theme.font_size.small};
    color: #fff;
    margin: 1rem 0;
  }
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  margin-bottom: 2rem;
  user-select: none;
  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    max-height: auto;
    display: flex;
    flex-flow: column;
  }
`
export const TextWrapper = styled.div`
  padding: 0 2rem;
`
