import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 15vh 0;
  margin-top: 5vh;
  color: #fff;
  p {
    ${props => props.theme.font_size.small};
    color: #fff;
    margin: 1rem 0;
  }
`

export const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-height: 115vh;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: 50vh 50vh 1fr 50vh;
    max-height: none;
    overflow-x: hidden;

    & .image {
      height: 50vh;
    }

    & .image1 {
      grid-row: 2;
    }
    & .image2 {
      grid-row: 4;
    }

    & .backgroundArt {
      display: none;
    }
  }
`

export const Title = styled.h1`
  ${props => props.theme.font_size.large};
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
`

export const Text = styled.p`
  ${props => props.theme.font_size.small};
  text-align: left;
  padding: 0 15%;
  line-height: 45px;
  align-self: center;
  span {
    color: #09bfec;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 1rem;
  }
`
